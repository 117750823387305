.Project {
    margin: 20px;
    font-size: 20px;
    width: 512px;
}
.Project > p {
    margin: 10px;
}
.Project > img {
    width: 512px;
    height: 256px;
    border-radius: 12px;
    object-fit: cover;
}
.Project > .tags {
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
}
.Tag {
    user-select: none;
}
.Tag > p{
    margin: 6px;
    padding: 2px 10px 2px 10px;
    border-radius: 200px;
    border: solid rgba(192, 187, 187, 0.281) 1px;
    font-size: 14px;
    color: rgb(255, 255, 255);
}
.orange {
    background-color: rgb(190, 69, 13);
}
.blue {
    background-color: rgb(16, 69, 129);
}
.yellow {
    background-color: rgb(197, 161, 2);
}

.green {
    background-color: rgb(29, 105, 54);
}

.purple {
    background-color: rgb(98, 52, 141);
}

.visit {
    padding: 5px 15px 5px 15px;
    background-color: rgba(94, 94, 94, 0.438);
    width: fit-content;
    border-radius: 200px;
}

@media only screen and (max-width: 800px) {
    .Project {
        font-size: 20px;
        width: 300px;
    }
    .Project > img {
        height: 160px;
        width: 300px;
    }
    .Tag > p {
        font-size: 10px;
    }
    .Project > p {
        font-size: 16px;
    }
    .visit {
        font-size: 14px;
    }
}