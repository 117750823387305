* {
  margin: 0px;
  padding: 0px;
}
html {
  height: 100vh;
}
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(9, 11, 17);
}

