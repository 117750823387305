.About {
    color: white;
    margin: 160px;
    width: 50vw;
}

.About > h1 {
    font-size: 48px;
    margin: 20px;
}
.About > hr {
    margin: 20px 0px 20px 0px;
}
.About > p {
    font-size: 21px;
    margin: 20px;
}


@media only screen and (max-width: 800px){
    .About {
        width: 96vw;
        margin: 125px;
    }
    .About > p {
        font-size: 18px;
    }
}