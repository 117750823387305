@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import '@material-design-icons/font';

.App {
  width: 100%;
}
.Header {
  font-family: 'Montserrat', sans-serif;
  backdrop-filter: blur(16px);
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 0px 8px 0px;
  font-size: 20px;
  background-color: rgba(58, 58, 58, 0.11);
  color: rgb(255, 244, 239);
  border-bottom: solid rgba(87, 87, 87, 0.548) 1px;
  z-index: 99;
}
.Header > h3 {
  margin: 6px;
}
.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.desc {
  opacity: 0;
  position: absolute;
  bottom: -30px;
  background-color: rgb(19, 20, 22);
  padding: 6px 12px 6px 12px;
  border: solid rgba(78, 78, 78, 0.548) 1px;
  border-radius: 200px;
  transition: 200ms;
  user-select: none;
}
.desc > p {
  font-size: 14px;
}
.options {
  display: flex;
}


.material-icons {
  margin: 0px 2vw 0px 2vw;
  padding: 12px;
  font-size: 32px;
  background-color: rgba(56, 56, 56, 0.404);
  color: rgb(231, 231, 231);
  border: solid rgba(255, 255, 255, 0) 1px;
  border-radius: 2000px;
  transition: 200ms;
  user-select: none;
  cursor: pointer;
}
.material-icons:hover {
  color: rgb(81, 245, 155);
  background-color: rgba(17, 17, 17, 0);
}

.material-icons:hover + .desc {
  opacity: 1;
}


.PageContent {
  font-family: 'Montserrat', sans-serif;
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Welcome {
  text-align: center;
  color: rgb(233, 233, 233);
  white-space: nowrap;
  margin: 200px 0px 0px 0px;
  font-size: 24px;
  animation: 800ms slide ease-in-out;
}

@keyframes slide {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.Skills {
  color: rgb(241, 241, 241);
}
.Skills > h2 {
  margin: 10px;
}
.skills-content {
  display: flex;
  width: 75vw;
  overflow-x: scroll;
}
.Skill {
  margin: 0px 4px 8px 4px;
  user-select: none;
  animation: 1s skill-spawn ease-out;
}

@keyframes skill-spawn {
  from {
    margin-left: 50%;
    opacity: 0%;
  }
  to {
    margin-left: 4px;
    opacity: 100%;
  }
}
::-webkit-scrollbar {
  height: 10px;              
  background-color: rgba(255, 255, 255, 0.123);
  border-radius: 200px;
  padding:20px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(240, 245, 255, 0.548);
  border-radius: 200px;
}
.overlay > img {
  background: linear-gradient(180deg, rgba(0,0,0,0.5326505602240896) 0%, rgba(18,51,139,0) 65%);
  width: 256px;
  height: 256px;
  object-fit: cover;
  position:relative;
  border-radius: 25px;
  z-index: -1;
}

.overlay {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(18, 18, 19, 0.877) 0%, rgba(18,51,139,0) 45%);
  border-radius: 22px;
}
.Skill > h3 {
  position: relative;
  top: 70px;
  font-size: 28px;
  margin: 0px 0px 0px 10px;
}

h2 {
  font-size: 32px;
}
strong > p {
  position: relative;
  top: 70px;
  color: rgb(255, 255, 255);
  font-size: 13px;
  margin: 0px 10px 0px 10px;
}
hr {
  border: none;
  border-top: solid rgba(139, 139, 139, 0.521) 1px;
}

.Footer {
  text-align: center;
  padding: 20px;
  background-color: rgba(92, 92, 110, 0.199);
  color: white
}

@media only screen and (max-width: 800px) {
  .Header {
    position: fixed;
    top:auto;
    bottom: 0;
    border-top: solid rgba(87, 87, 87, 0.548) 1px;
  }
  .desc {
    bottom: 55px;
  }
  .Header > h3 {
    display: none;
  }
  .overlay > img  {
    width: 200px;
    height: 200px;
  }
  strong > p {
    font-size: 10px;
  }
  .skills-content {
    width: 100vw;

  }
  .material-icons {
    margin: 0vw 10vw 0vw 10vw; 
  }
  .Welcome {
    font-size: 15px;
    margin: 65px 0px -50px 0px;
  }
  .Footer {
    padding: 20px 20px 95px 20px;
  }
  
}
